<template>
  <div class="mx-3 py-5">

  <v-col md="12"></v-col>   
    <v-row>
      <v-col md="3">
        <v-text-field :label="$t('hr.time.from-date')" type="date" v-model="filters.from" outlined dense>
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field :label="$t('hr.time.to-date')" type="date" v-model="filters.to" outlined dense>
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field v-model="filters.serial" :label="$t('Invoice Number')" outlined dense></v-text-field>
      </v-col>
      <v-col md="3">
        <v-autocomplete v-model="filters.invoice_type_name" :label="$t('Invoice Name')" dense item-text="name" item-value="name"
          outlined :items="items" clearable></v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-autocomplete v-model="filters.invoice_type" :label="$t('Invoice Type')" dense item-text="invoiceArLabel"
          item-value="type" outlined :items="items" clearable></v-autocomplete>
      </v-col>

      <!-- <v-col md="3">
          <v-text-field
            v-model="filters.client_name"
            :label="$t('Client Name')"
            outlined
            dense
          ></v-text-field>
        </v-col>
       --><v-col md="3">
        <v-autocomplete v-model="filters.deliveried" :label="$t('Invoice State')" dense item-text="name" item-value="value"
          outlined :items="stateDeliveried" clearable></v-autocomplete>
      </v-col>
      <v-col md="6">
        <v-text-field v-model="filters.notes" :label="$t('notes')" outlined dense></v-text-field>
      </v-col>
      <!-- <v-col md="6"></v-col> -->
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="blue" dark block @click="exportToExcel(filters)">{{ $t('hr.time.export') }}
        </v-btn>
      </v-col>

    </v-row>



    <v-data-table :dark="$store.state.isDarkMode" :headers="headers" :loading="loading" :items="tableItems" class="elevation-1 py-5" hide-default-footer
      :items-per-page="10">
      <template v-slot:item="{ item }">
        <tr :class="{ 'background-row': shouldMakeRowBackground(item) }">
          <td>{{ item.id }}</td>
          <td>{{ item.order_serial }}</td>
          <td>{{ item.order_date }}</td>
          <td>{{ item.invoice_type_name }}</td>
          <td>{{ item.client_account }}</td>
          <td>{{ item.deliveried }}</td>
          <td>{{ item.notes }}</td>
          <td style="width: 10%;">
            <v-btn @click="invoiceTypeToHandler(item)" color="blue" icon>
              <v-icon class="ml-1">mdi-truck-fast-outline</v-icon></v-btn>
          </td>
        </tr>
      </template>

    </v-data-table>
    <v-pagination v-if="!loading && items.length" @input="getAll" v-model="page"
      :length="Math.ceil(total / 10)"></v-pagination>
  </div>
</template>
  
<script>
import axios from "axios";
import deleteInvoice from "../../components/core/delete.vue";
import { saveAs } from 'file-saver';
import i18n from "../../../../i18n";

export default {
  components: { deleteInvoice },
  computed: {
    shouldMakeRowBackground() {
      return (item) => {
        if (item.deliveried == "done") {
          return true;
        }
        return false;
      };
    }

  },
  data: () => ({
    stateDeliveried: [
      {
        name:"مسلمة بالكامل",
        value: "done",
      },
      {
        name:"مسلمة جزئيا",
        value: "partial",
      },
      {
        name:"غير مسلمة",
        value: "none",
      },
    ],
    types: [
      {
        label: i18n.t('purchase'),
        value: "PURCHASES",
      },
      {
        label: i18n.t('sales'),
        value: "SALES",
      },
      {
        label: i18n.t('purchase refunds'),
        value: "PURCHASE_REFUNDS",
      },
      {
        label: i18n.t('sales refunds'),
        value: "SALES_REFUNDS",
      },
      {
        label: i18n.t('transportation'),
        value: "TRANSPORTATION",
      },
    ],
    tableItems: [],
    invoiceTypes: [],
    loading: false,
    page: 1,
    total: 1,
    headers: [
      { text: i18n.t('id'), value: "id" },
      { text: i18n.t('Order Number'), value: "order_serial" },
      { text: i18n.t('Order Date'), value: "order_date" },
      { text: i18n.t('Invoice Name'), value: "invoice_type_name" },
      { text: i18n.t('Vendor Name'), value: "client_account" },
      { text: i18n.t('FULL'), value: "deliveried" },
      { text: i18n.t('notes'), value: "notes" },
      { text: "", value: "actions" },
    ],
    items: [],
    filters: {
      from: null,
      to: null,
      id: null,
      serial: null,
      invoice_type_name: null,
      invoice_state: null,
      status: "1",
      invoice_type: null,
      client_name: null,
      notes: null,
    },
  }),
  methods: {
    clearFilter() {
      this.filters.from = undefined;
      this.filters.to = undefined;
      this.filters.id = undefined;
      this.filters.status = "1";
      this.filters.serial = undefined;
      this.filters.invoice_type_name = undefined;
      this.filters.invoice_type = undefined;
      this.filters.client_name = undefined;
      this.filters.notes = undefined;
      this.page = 1;
      this.getAll();
    },
    async exportToExcel(queryParams) {
      try {
        const excel = await axios.get(`/inventories/invoices/export`, {
          params: {
            ...queryParams,
          },
          responseType: 'blob'
        });
        saveAs(excel.data)
      } catch (err) {
        console.log('err', err);
      } finally {

      }
    },
    invoiceTypeToHandler(item) {
      this.$router.push(
        `/edit-request/${item.id}?invoice_id=${item.invoice_type_id}&wear_house=${1}`
      );
    },
    async getAll(page) {
      try {
        if (!page) this.page = 1;
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        this.loading = true;
        const res = await axios.get("/inventories/invoice-request/delivery", {
          params: {
            size: 10,
            page: this.page,
            ...filterTemp,
          },
        });
        this.tableItems = res.data.data.map((el) => {
          const typeTemp = this.types.find(
            (typ) => el?.invoice_type?.type == typ.value
          );
          return {
            ...el,
            invoiceTypeName: el?.invoice_type?.name,
            clientAccountName: el?.client_account?.name,
            type: typeTemp ? typeTemp.label : " ",
            invoiceStatus: el.is_locked ? "Locked" : "UnLocked",
          };
        });

        this.total = res.data.meta.total;
      } finally {
        this.loading = false;
      }
    },
    invoiceTypeFormatter(invoiceType) {
      const invoiceTemp = [
        {
          label: "فاتورة مشتريات",
          value: "PURCHASES",
        },
        {
          label: "فاتورة مبيعات",
          value: "SALES",
        },
        {
          label: "فاتورة مرتجع المشتريات",
          value: "PURCHASE_REFUNDS",
        },
        {
          label: "فاتورة مرتجع المبيعات",
          value: "SALES_REFUNDS",
        },
        {
          label: "فاتورة مناقلة",
          value: "TRANSPORTATION",
        },
      ].find((el) => el.value == invoiceType?.type);
      let str = "";
      if (invoiceTemp) {
        str = ` ${invoiceType.name}`;
      }
      return str;
    },
    itemClicked() {

      this.$router.push(`/deliveriedOrder`);

    },
    invoiceTypeLabelFormatter(type) {
      return [
        {
          label: "فاتورة مشتريات",
          value: "PURCHASES",
        },
        {
          label: "فاتورة مبيعات",
          value: "SALES",
        },
        {
          label: "فاتورة مرتجع المشتريات",
          value: "PURCHASE_REFUNDS",
        },
        {
          label: "فاتورة مرتجع المبيعات",
          value: "SALES_REFUNDS",
        },
        {
          label: "فاتورة مناقلة",
          value: "TRANSPORTATION",
        },
      ].find((el) => el.value == type).label;
    },
    async getInvoiceTypes() {
      const res = await axios.get("/inventories/invoice-types/index");
      this.items = res.data.data.map((el) => ({
        ...el,
        invoiceArLabel: this.invoiceTypeLabelFormatter(el.type),
      }));
      console.log(this.items);
    },
  },
  created() {
    this.getInvoiceTypes();
  },
};
</script>
  
<style>
.background-row {
  background: #e6f3f2;
  /* font-weight: bold; */
}
</style>
  